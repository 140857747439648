/* General Chat Container */
.chat-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #e1e4e8;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

/* Header */
.chat-header h2 {
  font-size: 1.6rem;
  color: #2c3e50;
  margin-bottom: 8px;
}

.chat-header h3 {
  font-size: 1.2rem;
  color: #7f8c8d;
  margin: 0;
}

/* Chat Window - Messages */
.chat-window {
  height: 400px;
  overflow-y: auto;
  margin-top: 20px;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

/* Individual Message Style */
.chat-message {
  margin-bottom: 15px; /* Ensures messages are spaced out */
  display: flex;
  flex-direction: column; /* Ensures the author and content are stacked vertically */
  padding: 10px;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
}

.chat-message:hover {
  transform: translateY(-5px); /* Slight hover effect */
}

/* User Message */
.user-message {
  text-align: left;
  background-color: #3498db; /* Blue for user */
  color: white;
  border-radius: 8px;
  padding: 10px;
  max-width: 70%;
  margin-left: 0;
  margin-right: auto;
}

/* AI Message */
.ai-message {
  text-align: left;
  background-color: #2ecc71; /* Green for AI */
  color: white;
  border-radius: 8px;
  padding: 10px;
  max-width: 70%;
  margin-left: auto;
  margin-right: 0;
}

/* Message Author */
.message-author strong {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Message Content */
.message-content p {
  margin: 0;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.6;
}

/* Chat Input Section */
.chat-input-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 15px;
}

.chat-input {
  flex-grow: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.chat-send-button {
  padding: 12px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.chat-send-button:hover {
  background-color: #2980b9;
}

.chat-send-button:active {
  background-color: #1f6d96;
}

