/* Global styles */
body {
  font-family: Arial, sans-serif;
  background: #f2f2f2; /* Light grey background */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.register-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 100%;
  max-width: 400px;
}

.register-container h2 {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.register-container input {
  padding: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 15px;
  width: 100%;
  background-color: #f9f9f9; /* Light grey background */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.register-container input:focus {
  background-color: #fff; /* White background on focus */
  border-color: #ff6a13; /* Dark orange border on focus */
  box-shadow: 0 0 5px rgba(255, 106, 19, 0.3); /* Subtle shadow on focus */
}

/* Register Button - matching the login button style */
.register-button {
  padding: 12px 20px; /* Same padding as login button */
  background-color: #e65c0f; /* Orange color */
  color: white; /* White text color */
  border: none;
  border-radius: 4px;
  font-size: 16px; /* Same font size as login button */
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.register-button:hover {
  background-color: #ff6a13; /* Darker orange on hover */
}

/* Error message */
.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

footer {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}

footer a {
  color: #ff6a13; /* Dark Orange */
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.or-text {
  text-align: center;
  font-size: 14px;
  margin: 20px 0;
  color: #666;
}
